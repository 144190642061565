import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

import { DialogService } from '~services/dialog/dialog.service';
import { User } from '~models/user/user.model';
import { UserService } from '~services/user/user.service';
import { TutorialService } from '~services/tutorial/tutorial.service';
import { DialogSwitchUserComponent } from '~dialogs/switch-user/switch-user.component';
import { AuthService } from '~services/auth/auth.service';
import { ConfigService } from '~services/config/config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  user: User;
  doc_url: string;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private tutorialService: TutorialService,
    private configService: ConfigService,
    public dialog: DialogService,
    public translate: TranslateService
  ) {
    this.doc_url = this.configService.get('RTFM').URL;

    this.userService.user$.subscribe((user) => {
      this.user = user;
    });
  }

  featureFlags = this.configService.get('FEATURE_FLAGS');
  openWelcomeHelpModal() {
    this.tutorialService.openTutorial();
  }

  requestAccess(feature) {
    this.userService.requestAccess(feature);
  }

  resetCredentials() {
    this.userService.logout();
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }

  openSwitchUserModal() {
    this.dialog
      .open(DialogSwitchUserComponent, {
        scrollStrategy: new NoopScrollStrategy()
      })
      .afterClosed()
      .subscribe((user) => {
        if (user) {
          this.authService.sudo(user.id);
        }
      });
  }
}
