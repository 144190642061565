{
  "portal": {
    "portal-form": {
      "CREATE-PORTAL": "Portal settings",
      "ADD-LIVEMAP": "Add map",
      "HEADER": "Header",
      "ADD-LOGO": "Add logo",
      "INTRO": "Introduction",
      "TITLE": "Title",
      "SUBTITLE": "Subtitle",
      "ADD-BACKGROUND": "Insert a background image",
      "SEE-LIVEMAP": "See livemap",
      "NO-LIVEMAP": "No map added",
      "SAVE": "Save"
    },
    "portals": {
      "SEARCH-PORTAL": "Search in portal...",
      "TITLE": "Title",
      "SHARE": "Share",
      "SHARE-PORTAL": "Share portal",
      "MANAGE-LIVEMAP": "Manage maps",
      "MAP-COUNT": "Map count",
      "INTRO-HELP": "In this tab you can manage portals you created and modifiy them",
      "DELETE-PORTAL": "Delete portal",
      "DELETE-PORTALS": "Delete portals",
      "REALLY-DELETE": "Do you really want to delete the portal",
      "REALLY-BULK-DELETE": "Do you really want to delete",
      "VISIT": "Visit portal",
      "PORTAL": "portal"
    },
    "notification": {
      "PORTAL": "Portal '",
      "PORTAL-MAPS": "Portal '",
      "SAVED": "' has been saved",
      "CREATED": "' has been created",
      "ERROR-GETTING-PORTAL": "Something went wrong when getting portal.",
      "ERROR-GETTING-PORTALS": "Something went wrong when getting portals.",
      "ENTER-TITLE": "Please enter a title",
      "SELECT-MAPS": "Please select some maps",
      "UPLOAD-BANNER": "Please upload a banner image",
      "ERROR-DELETING": "Something went wrong when deleting portal - ",
      "ERROR-DELETING-SELECTION": "Something went wrong by removing the portals.",
      "DELETING-SELECTION": "The portals have been successfully removed.",
      "UPDATED": "' maps has been updated succesfully",
      "DELETED": "' deleted succesfully",
      "ERROR-CREATING": "Something went wrong when creating portal.",
      "ERROR-UPDATING": "Something went wrong when updating portal."
    }
  },
  "geoentity": {
    "STYLE": "Style",
    "STROKE": "Stroke",
    "STROKE-COLOUR": "Stroke colour",
    "STROKE-OPACITY": "Stroke opacity",
    "STROKE-WIDTH": "Stroke width",
    "STROKE-FORM": "Stroke form",
    "UNIFORM": "Uniform",
    "DOTTED": "Dotted",
    "DASH": "Dash",
    "SPACE": "Space",
    "FILL": "Fill",
    "FILL-COLOUR": "Fill colour",
    "FILL-OPACITY": "Fill opacity",
    "PERSONALIZE": "Personalize Geoentities"
  },
  "trip": {
    "trips": {
      "SEARCH": "Search",
      "SEARCH-PPS": "Search in trips..."
    },
    "trip-form": {
      "NAME": "Name",
      "DESCRIPTION": "Description",
      "TAGS": "Tags",
      "ADD-TAGS": "Add tags",
      "LINKED-LISTS": "Linked list",
      "ADD": "Add polyline",
      "ADD-WAYPOINT": "Add waypoint",
      "ADD-WAYPOINTS": "Add waypoints",
      "HOW-TO-ADD": "How do you want to add waypoints ?",
      "FILE-FORMAT": "Only GPX, GEOJSON, KML formats of a maximum size of 24MB are suported",
      "ERROR-NAME": "Add a name to your polyline",
      "MESSAGE-SUCCESS-SAVE": "Polyline { name } has been saved",
      "MESSAGE-ERROR-SAVE": "An error occured during the save",
      "OPTIONAL": "Optional",
      "WAYPOINTS": "Waypoints",
      "INFO-UNIQUE-FEATURE": "You can edit only one feature",
      "INFO-EDITION": "Please edit a polyline",
      "WAYPOINT-INSTRUCTION": "Add a waypoint by clicking on the map",
      "WAYPOINT-EDIT": "Update the coordinates of the waypoint by clicking on the map",
      "WAYPOINT-EDIT-CONTENT": "Edit the content",
      "WAYPOINT-BTNS": {
        "XLSX": "Import an xlsx file",
        "LIST": "Import a list",
        "POINT": "Import a point",
        "MANUAL": "Create a waypoint manually"
      },
      "IMPORTED-LIST": "The list has just been imported.",
      "IMPORTED-XLSX": "The xlsx file has just been imported.",
      "GENERATE-TRACE": "Generate the trace",
      "EDIT-TRACE": "Edit the trace",
      "HOW-TO-TRACE": "How do you want to generate the trace ?",
      "TRACE-BTNS": {
        "AUTO": "Automatic calculation from steps",
        "MANUAL": "Pathway editing",
        "IMPORT": "Import a file (GPX, GEOJSON, KML)"
      },
      "ORDERING": "Number the steps",
      "INFO-ALREADY-TRACE": "A pathway already exists, it will be overwritten if you continue."
    },
    "modal-waypoint": {
      "WAYPOINT": "Waypoint",
      "TITLE-CREATION": "Create a waypoint",
      "TITLE-UPDATE": "Update the waypoint",
      "WAYPOINT-TITLE": "Title",
      "WAYPOINT-DESCRIPTION": "Description",
      "WAYPOINT-IMAGE": "Image"
    }
  },
  "area": {
    "areas": {
      "SEARCH": "Search",
      "SEARCH-PPS": "Search in areas..."
    },
    "area-form": {
      "NAME": "Name",
      "DESCRIPTION": "Description",
      "TAGS": "Tags",
      "LINKED-LISTS": "Linked list",
      "EDIT-POLYGONE": "Edit polygone",
      "ERROR-NAME": "Add a name to your polygone",
      "MESSAGE-SUCCESS-SAVE": "Polygone { name } has been saved",
      "MESSAGE-ERROR-SAVE": "An error occured during the save",
      "INFO-MSG": "Please add polygon",
      "INFO-UNIQUE-FEATURE": "You can edit only one feature",
      "INFO-EDITION": "Please create a polygone to save it"
    }
  },
  "pinpoint": {
    "pinpoint-form": {
      "LINKED-LIST": "Linked list",
      "EXTERNAL_DATA": "External data",
      "MEDIA-CREDITS": "Media credits",
      "MEDIA": "Media",
      "TYPE": "Type",
      "EXTERNAL-ID": "External id",
      "OUTDOOR": "Pinpoint extérieur",
      "CATEGORY": "Category",
      "PICTURE": "Picture",
      "VIDEO": "Video",
      "LINKED-LISTS": "Linked list",
      "ONLY-PIC": "Only JPEG and PNG picture formats of maximum size 24 MB are supported.",
      "REMOVE": "Remove",
      "CORRECT-MEDIA": "Please upload the correct media Format.",
      "FILE-SIZE": "Please make sure file size is less than 24 MB.",
      "ONLY-VID": "Only MOV and MP4 video formats of maximum size 24 MB are supported.",
      "ONLY-YOUTUBE": "Only Youtube, Dailymotion and Vimeo video are supported.",
      "NAME": "Name",
      "ADDRESS": "Address",
      "DESCRIPTION": "Description",
      "TAGS": "Tags",
      "LINK": "Link this point to a feed",
      "ENTER-COORDINATES": "To add a point you can enter coordinates or directly",
      "CLICK-ON-MAP": "click on the map",
      "LOCATION": "Location",
      "PLACE-ADDRESS": "Address, place...",
      "LINK-URL": "Link",
      "COUNTRY": "Country code",
      "PHONE": "Phone",
      "FACEBOOK-URL": "Facebook page",
      "TWITTER-URL": "Twitter page",
      "OPENING-HOUR": "Opening hours",
      "OPTIONAL": " - Optional",
      "MONDAY": "Monday",
      "TUESDAY": "Tuesday",
      "WEDNESDAY": "Wednesday",
      "THURSDAY": "Thursday",
      "FRIDAY": "Friday",
      "SATURDAY": "Saturday",
      "SUNDAY": "Sunday"
    },
    "pinpoint": {
      "OR-COORD": "or coordinates",
      "OR-ADDRESS": "or address",
      "ADD-IMAGE": "Add image",
      "ADD-VIDEO": "Add video",
      "ADD-DESC": "Add description",
      "CHANGE-IMAGE": "Change image",
      "CHANGE-VIDEO": "Change video",
      "REMOVE-IMAGE": "Remove image",
      "CREATE-CAROUSEL": "Create carousel",
      "ADD-EXTRA-MEDIA": "Add images",
      "CAROUSEL-IMAGES": "Carousel",
      "CAROUSEL-IMAGES-DESC": "Add images to create a carousel",
      "NO": "no",
      "S": "s",
      "FEEDS-LINKED": "Feeds linked ",
      "FEED": "feed",
      "COMMENTS": "Comments",
      "SEARCH": "Search",
      "INTRO-HELP": "Click the +  button to add a linked list to the point or select an existing one",
      "NO-COMMENTS": "No comments to display",
      "MORE": "More",
      "CHANGE-STATUS": "Change status",
      "SEARCH-COMMENTS": "Search in comments...",
      "ADD-TO-LIST": "Add to list",
      "ADD-EVENT": "Add an event",
      "REMOVE-FROM-LIST": "Remove from list",
      "ICON": "Icon",
      "THUMBNAIL": "Thumbnail",
      "TRANSFORM-INTO-TRIP": "Transform into trip",
      "TRANSFORM-INTO-AREA": "Transform into area",
      "LINKED-EVENTS": "Linked events",
      "NO-LINKED-EVENTS": "No linked events"
    },
    "pinpoints": {
      "PRINT": "Print the point",
      "PPS": "Points",
      "CHANGE-CAT": "Change icon",
      "CHANGE-STATUS": "Change status",
      "ADD-SELE-TO-LIST": "Add selection to lists",
      "REMOVE-SELE-FROM-LIST": "Remove selection from this list",
      "DOWNLOAD-CSV": "Download as CSV",
      "DOWNLOAD-GEOJSON": "Download as GeoJSON",
      "ADD-TAGS": "Add tags",
      "SEARCH": "Search",
      "SEARCH-PPS": "Search in points...",
      "INTRO-HELP": "In this tab you can manage points you created manually or which were automatically added (from import, apps...)",
      "CHANGE-TYPE": "Change display mode",
      "PUBLISH": "Publish",
      "DRAFT": "Move to draft",
      "PREVIEW": "Preview",
      "DUPLICATE": "Duplicate",
      "LAT-LNG": "With latitude / longitude",
      "PUBLIC": "Search all public points",
      "HASHTAG": "Search by tag",
      "LOCATION": "Search by location",
      "ADDRESS": "With an address",
      "ADD-FIELD": "Add column",
      "EDIT-DESCRIPTION": "Edit description",
      "ADD-ROW": "Add row",
      "DRAFT-PINPOINTS": "Points without location (draft)",
      "ADVANCED": "Advanced Options"
    },
    "modal": {
      "modal-edit-description": {
        "EDIT-DESCRIPTION": "Edit description",
        "FIELDS": "Fields",
        "DESCRIPTION": "Description",
        "CUSTOM-DESCRIPTION": "Custom description",
        "HELPER-TEXT": "You can here add the fields from your custom columns to your description."
      },
      "modal-add-feed-to-pinpoint": {
        "ADD-FEED": "Add a feed",
        "ADD-FEED-TO-PP": " Add feed to point",
        "FEED-TYPE": "Feed type",
        "FEED-NAME": "Feed name",
        "FEED-URL": "Feed URL"
      },
      "modal-add-tags-to-pinpoints": {
        "ADD-TAGS-TO-PP": "Add tags to point",
        "ADD-TAGS-TO-PPS": "Add tags to points",
        "TAGS": "Tags",
        "TAGS-PLACEHOLDER": "Use \" , \" to separate tags ..."
      },
      "modal-change-type": {
        "PP-TYPE": " Display type",
        "TYPE": "Type",
        "ICON": "Icon",
        "THUMBNAIL": "Thumbnail"
      },
      "modal-delete-pinpoint": {
        "DELETE-PP": " Delete point",
        "REALLY-DELETE": "Do you really want to delete the point",
        "REALLY-DELETE-NAMED": "Do you really want to delete the point"
      },
      "modal-delete-pinpoints": {
        "DELETE-PPS": " Delete points",
        "REALLY-DELETE": "Do you really want to delete",
        "REALLY-DELETES": "Do you really want to delete",
        "PP": "point",
        "S": "s"
      },
      "modal-edit-feed": {
        "EDIT-FEED": "Edit feed",
        "FEED-NAME": "Feed name",
        "FEED-URL": "Feed URL"
      },
      "modal-livemap-pinpoint": {
        "MAP-PP": "Map for point"
      },
      "modal-picture-pinpoint": {
        "PP-PIC": " Pinpoint's picture",
        "PP-VID": "Point's video"
      },
      "modal-share-pinpoint": {
        "SHARE-PP": "Share point",
        "PREVIEW": "Preview"
      },
      "modal-duplicate-list": {
        "DUPLICATE-LIST": "Duplicate list",
        "DUPLICATE-LIST-PPS": "Do you want to duplicate the points of this list ?"
      },
      "modal-get-select-lists": {
        "NO-LISTS": "No lists",
        "SELECT-ALL": "Select all",
        "LIST-NAME": "List name",
        "SELECT-EXISTING-LIST": "Select an existing list",
        "CREATE-LIST": "Add to a new list"
      },
      "modal-select-single": {
        "NO-PPS": "No points to display"
      }
    },
    "notification": {
      "POINT": "Point '",
      "FILL-SCHEDULE": "Please fill the schedule correctly (opening hour >= closing hour).",
      "FILL-MANDATORY": "Please fill the mandatory fields.",
      "DUPLICATED": "The list has been duplicated",
      "OPENIN-CLOSING-EMPTY": "Opening and/or Closing hour shouldn't be empty.",
      "ERROR-FETCHING-ADRESS": "Error while fetching Point address",
      "ERROR-FETCHING-COORDINATES": "Error while fetching point coordinates",
      "ERROR-COORDINATES-NOT-VALID": "The coordinates are not valid.",
      "ERROR-FETCHING-TIMEZONE": "Error while fetching point timezone",
      "ERROR-GETTING-POINT": "Something went wrong when getting point.",
      "ERROR-GETTING-POINTS": "Something went wrong when getting points.",
      "ERROR-SHARE": "Something went wrong when getting share links.",
      "ERROR-UPDATING": "Something went wrong when updating point.",
      "ERROR-UPDATING-S": "Something went wrong when updating points.",
      "ERROR-BULK-UPDATE": "Something went wrong when updating points. { updated } points updated on a total of { total } points.",
      "ERROR-ADDING": "Something went wrong when adding points to list '",
      "ERROR-REMOVING": "Something went wrong when removing points from list '",
      "ERROR-DELETING-LIST": "Something went wrong when deleting list's points - ",
      "REMOVED": "Pinpoints have been removed from list",
      "NEED-NAME": "Point need a name before being updated",
      "UPDATED": "' has been updated",
      "UPDATED-S": "points have been updated",
      "CREATED": "' has been created",
      "DELETED": "' has been deleted",
      "DELETED-S": "points have been deleted",
      "PUBLISHED": "' has been published",
      "PUBLISHED-S": " points have been published",
      "NEED-COORDINATES": "' needs coordinates to be published.",
      "DRAFT": " has been moved to draft.",
      "DRAFT-S": " points have been moved to draft.",
      "DUPLICATE-LOADING": "Duplicate in progress",
      "DUPLICATE-SUCCESS": "Pinpoints have been duplicated.",
      "DUPLICATE-ERROR": "An error occured during the duplication process.",
      "ADDED": "Pinpoints have been added to list '",
      "WARNING-NO-MAPPING": "Description will be cleared",
      "PHONE-INFORMATION": "The 'Country' field must be filled out when entering a phone number. The phone number must have the correct number of digits as per the standards of the indicated country."
    }
  },
  "feed": {
    "feed-form": {
      "TYPE": "Type",
      "NAME": "Name",
      "URL": "URL",
      "ICON-OPTIONS": "Choose an icon",
      "IMPORT-INTERVAL": "Update interval",
      "IMPORT-INTERVAL-CUSTOM": "Interval update duration (in seconds)",
      "OTHER-OPTIONS": "Other options",
      "ADVANCED-OPTIONS": "Advanced options",
      "ONLY-PICTURE": "Only import items with a picture",
      "AUTO": "Auto publish new points from this feed",
      "RSS-INFO": "Use RSS url with http:// or https://. Format - http://atlasobscura.com.feedsportal.com/c/35387/f/665719/index.rss",
      "TWITTER-INFO": "Use from:user_name or #name_of_your_tag. (For User https://twitter.com/getwemap, enter from:getwemap. For hashtag https://twitter.com/hashtag/wemap, enter #wemap.)",
      "TWITTER-GEOCODE-INFO": "Limit points to a zone by drawing a circle area on the map",
      "FB-INFO": "Use user_name(For Page https://www.facebook.com/maaapcompany, enter maaapcompany). Use groups/1515368048690185/ or events/521880024642068/ or pages/Theberry-Alaska/1417997928453880 or full url https://www.facebook.com/groups/1515368048690185/.",
      "AFP-INFO": "Use url with set of criteria. (Format - https://api.afp.com/v1/api/search?q=topic:People, https://api.afpforum.com/v1/api/search?q=topic:Vidéos%20Monde&lang=fr)",
      "NYTIMES-INFO": "Use url with set of criteria. (Format - http://api.nytimes.com/svc/events/v2/listings.json?query=dance&api-key=xxxx, http://api.nytimes.com/svc/mostpopular/v2/mostemailed/all-sections/1.json?api-key=xxxx)",
      "PARI-INFO": "Use url with set of criteria. (Format - http://api.leparisien.fr/articles?token=xxxx&rubriques=pdcm%3Aoise)",
      "YOUTUBE-INFO": "Use channel/name_of_your_channel or your_query or user_name. (For channel https://www.youtube.com/channel/UCXwDLMDV86ldKoFVc_g8P0g, enter channel/UCXwDLMDV86ldKoFVc_g8P0g. For search phrase https://www.youtube.com/results?search_query=paris , enter paris. For user https://www.youtube.com/user/aMOODIEsqueezie, enter user/aMOODIEsqueezie )",
      "INSTA-INFO": "Use user_name or hashtag/name_of_your_tag. (For user https://instagram.com/wemap, enter wemap. For hashtag https://instagram.com/explore/tags/getwemap, enter hashtag/getwemap.)",
      "TOURIN-INFO": "Use url with set of criteria. (Format - https://wcf.tourinsoft.com/Syndication/3.0/cdt44/fa77e985-81ca-41d3-8ebb-c141357cf37b/Objects)",
      "OPENAGENDA-INFO": "Use url with set of criteria. (Format - https://openagenda.com/agendas/51780064/events.json?page=1&search%5Bpassed%5D=1)",
      "OPENDATASOFT-INFO": "Use Opendatasoft url. (Format - https://data.iledefrance.fr/api/records/1.0/search/?dataset=ipass-350)",
      "INFOLOCALE-INFO": "Use url with set of criteria. (Format - http://datainfolocale.opendatasoft.com/api/records/1.0/search/?dataset=agenda_culturel&rows=123)",
      "WCITIES-INFO": "Use short api url with set of criteria. (Format - https://dev.wcities.com/V3/event_api/getEvents.php?lat=48.85&lon=2.35&searchFor=rock, http://dev.wcities.com/V3/event_api/getEvents.php?lat=37&lon=-122.4192&start=2017-12-20&end=2017-12-20)",
      "EVENTBRITE-INFO": "Use short api url with set of criteria. (Format - /events/search?q=montpellie, /users/9637573053/owned_events, /users/9637573053/bookmarks)",
      "LOCALEO-INFO": "Use short api url. (Format - https://grc28.localeo.fr/public/carto/getjsonforplatform/folder/78640/pltf/139/catId/0/ip/a8e860bff1dc9a84fc350b6a50736cf6/key/grc_data-COGINOV)",
      "CEETIZ-INFO": "Use api url with set of criteria. (Format - https://api.ceetiz.com/v1/products?destination=5)",
      "PARTIRENLIVRE-INFO": "Use api url with set of criteria. (Format - https://www.partir-en-livre.fr/wp-json/wp/v2/fdl_event)",
      "SORTIRVOSGES-INFO": "Use api url with set of criteria. (Format - https://www.sitlor.fr/xml/exploitation/listeproduits.asp)",
      "TRIPADVISOR-INFO": "Use api url. (Format - https://api.tripadvisor.com/api/partner/2.0/map)",
      "PARTIRENLIVRE-2020-INFO": "Use api url. (Format - https://www.partir-en-livre.fr/wp-json/wp/v2/fdl_event)",
      "VENDEEGLOBE-INFO": "Use api url. (Format - https://www.vendeeglobe.org/en/api/village/program)",
      "MCBASE-LIEUX-META": "Use api url. (Format - https://cartes.culture.gouv.fr/api/places)",
      "JOTFORM-INFO": "Use api url. (Format - https://eu-api.jotform.com/form/203084041437346/submissions)",
      "DEUTSCHEBAHN-INFO": "Use api url. (Format - https://maps-test.reisenden.info/rimapsapi/0.7/station/poi/indoor/DB/byzoneid?zoneID=1071)",
      "EXCEL-INFO": "Use api url. (Format -https://getwemapms-my.sharepoint.com/:x:/g/personal/admin_getwemapms_onmicrosoft_com/EQunPaVEkptNrs65ErP5-EgBFrFGdzw3J3f6YgnqYKo7VA?e=9PXFez)",
      "LISTSHAREPOINT-INFO": "Use api url. (Format -https://nge1.sharepoint.com/sites/testwemap/Lists/International fr/AllItems.aspx)",
      "PARISJETAIME-INFO": "Use api url. (Format - https://parisjetaime.my.salesforce.com)",
      "FRANCETRAVAIL-INFO": "Use api url. (Format - https://api.pole-emploi.io/partenaire/offresdemploi/v2/offres/search?departement=71&codeROME=K1304%2CK1302%2CK1301%2CJ1301%2CK1305%2CJ1501%2CJ1506%2CM1609)",
      "JSON-HELP": "Connect your JSON feed by importing and mapping it. Choose the main array containing you values and map them to point's properties.",
      "MCAD-HELP": "Connect your MCAD feed by importing and mapping it. Choose the main array containing you values and map them to point's properties.",
      "BASELIEUX-HELP": "Connect your feed by importing and mapping it. (URL format: rootAPI + '/api/places' + query)",
      "XML-HELP": "Connect your XML feed by importing and mapping it. Choose the main node containing you values and map them to point's properties.",
      "CONNECT": "Connect",
      "RG-LOW-VALUE": "Maximum value low affluence",
      "RG-HIGH-VALUE": "Maximum value medium affluence",
      "UPDATE": "Update",
      "LANGUAGE": "Language",
      "CENTER": "Center",
      "API-KEY": "API Key",
      "CHOOSE": "Choose",
      "DRAFT-IMPORT": "Draft import",
      "DRAFT-IMPORT-WARNING": "No mapping for the location. Your points will be created as drafts. Do you want to continue ?"
    },
    "feed": {
      "DRAG-DROP": "Drag and drop values into properties",
      "NAME": "Name",
      "NO-LOC-SUGG": "Sorry no Location found",
      "APP-NAME": "App name"
    },
    "modal": {
      "modal-category-feed": {
        "PP-CAT": "Point's categories",
        "NEW-CAT": "New category",
        "OWN-CATEGORY": "Own category",
        "COMMON-CATEGORY": "Common category"
      },
      "modal-delete-feeds": {
        "DELETE-FEEDS": "Delete feeds",
        "REALLY-DELETE": "Do you really want to delete",
        "FEED": "feed",
        "S": "s"
      }
    },
    "table-view": {
      "USE-GEOCODING": "Use geocoding for address",
      "COLUMN-NAME": "Column name",
      "UPDATE-COLUMN-NAME": "Update column name",
      "columns": {
        "NAME": "Name",
        "LINK": "Link",
        "LATITUDE": "Latitude",
        "LONGITUDE": "Longitude",
        "ADDRESS": "Address",
        "TAGS": "Tags",
        "ICON": "Icon",
        "IMAGE": "Image",
        "COLUMN": "Column"
      },
      "EDIT-COLUMNS": "Edit Columns"
    },
    "notification": {
      "FEED": "Feed '",
      "FEED-IMPORT": "Feed '",
      "NO-DATA-FOUND": "No data found.",
      "NO-DATA-IMPORTED": "No data imported, your feed should already be up to date.",
      "ERROR": "Error retrieving data : ",
      "ERROR-LOADING": "Something went wrong when loading url.",
      "NOT-ARRAY": "The chosen path is not an array of objects.",
      "NUMBER-IMPORTED": "{ imported } data out of { total } have been updated.",
      "WARNING-IMPORT-INTERVAL": "The update interval must be between 1 hour and 1 week (3600 seconds and 604800 seconds).",
      "ERROR-GETTING-FEEDS": "Something went wrong when getting feeds.",
      "ERROR-GETTING-FEED": "Something went wrong when getting feed.",
      "ERROR-CREATING": "Something went wrong when creating feed.",
      "IMPORTING": "Importing feed ",
      "IMPORT-COMPLETE": "' import is now complete.",
      "SAVED": "' has been saved.",
      "BATCH-IMPORTED": "The first batch of pinpoints imported from the app will appear in a few minutes.",
      "UPDATING-FEED": "Updating feed ",
      "UPDATE-DESCRIPTION-DELAY": "The feed has been updated. The descriptions of your pinpoints will be generated in few minutes."
    },
    "error-interceptor": {
      "ERROR-DELETING": "Something went wrong when deleting feed type.",
      "ERROR-GETTING-FEED": "Something went wrong when getting feed type.",
      "ERROR-GETTING-FEEDS": "Something went wrong when getting feed types.",
      "ERROR-CREATING": "Something went wrong when creating feed type.",
      "ERROR-UPDATING": "Something went wrong when updating feed type."
    }
  },
  "apps": {
    "feeds": {
      "CONNECT": "Connect",
      "REQUEST-ACCESS": "Request access",
      "INTRO-HELP": "Here you can connect to an app (public/private) and load content in a dedicated list."
    },
    "feed-form": {
      "APP": "App",
      "THUMBNAIL": "Display pictures or videos thumbnails",
      "ALWAYS-ICON": "Always display an icon",
      "CHOOSE.PICTURES": "Choose your medias"
    },
    "notification": {
      "REQUEST": "Request access for ",
      "SEND-SUCCESSFULLY": " app successfully sent"
    }
  },
  "livemap": {
    "menu": {
      "MAP-RESUME": "Map Summary",
      "SETTINGS": "Layout, Controls and Map Launch",
      "MAP-SOURCES": "Map Content",
      "STYLING": "Styling",
      "SERVICES": "Services, Search and Ads",
      "NAVIGATION": "Navigation",
      "PRINT": "Print",
      "STATS": "Statistics"
    },
    "livemap-help": {
      "DIMENSIONS": "This option allows you to adapt your map’s width and height depending on the space in which you want to embed it. By default, width is set to 100%",
      "LANGUAGE": "This option allows you to choose your content’s language on the map",
      "MAP-STYLE": "This option allows you to choose a map background in a different language and style, in raster or vectorial",
      "LAYERS": "This option allows you to load a json layer which will be displayed above the map background",
      "LINKS": "This option allows you to manage links’ behavior in your content",
      "BOUNDS": "This option allows you to set the minimum and maximum details’ level (zoom levels) of your map",
      "NAVIGATION": "This option allows you to deactivate drag and scroll on your map, if needed",
      "SIDEBAR": "This option allows you to display or hide sidebar, and to select content to be displayed (source)",
      "PINPOINT-DISPLAYED": "This option allows you to choose maximum visible points on the map. The maximum recommended limit is 400 points. Your map has more than 400 points ? Contact us",
      "CLUSTERS": "This option allows you to gather points which are too close to each other and optimise their display on the map. You can choose to display a custom icon, your avatar (by default) or display number if aggregated points",
      "CLUSTERS-BACK": "Generate clusters server-side for improved performance on high traffic maps. Use sparingly as it means additional processing every hour",
      "CLUSTERS-RADIUS": "This option allows you to define at which distance in pixels points starts to aggragate",
      "MARKERS-SIZE": "This option allows you to define the size of markers appearing on the map",
      "VISIBLE-FIELDS": "This option allows you to choose which informations you want to display in points’ cell",
      "EXPAND-DESCRIPTION": "This option allows you to remove the “Read more” button in point’s description in order to display the entire text",
      "EVENTS": "This option allows you to optimise events display on your map (dates and hours display…)",
      "LANDING": "This option allows you to define an area at the opening of the map. Three options are possible: automatic, around you and fixed area",
      "INTRO-CARD": "This option allows you to define some preferences to filter content on the map: filter by dates, tags, area..",
      "LOCK": "This option allows you to lock navigation on the map",
      "FULLSCREEN-TAP": "On mobile, this option allows you to display a fullscreen map when you tap on it. (only available for script embed)",
      "AUTOPLAY": "This option allows you to trigger points’ automatic opening, in a random order",
      "MENU": "This option allows you to display or hide some menu options",
      "ZOOM": "This option allows you to display or hide “zoom in” and “zoom out” buttons on the map",
      "SEARCH": "This option allows you to display or hide search bar on the map",
      "SEARCH-GEOCODING": "This option allow you to deactivate the search by geocoding. You can also choose a country where this search will be made.",
      "CALENDAR": "This option allows you to force activation of dates filter (a calendar with selectable dates) even without events",
      "AROUND-ME": "This option allows you to display or hide the “Around me” button, which zooms on your position",
      "TAGS": "This option allows you to activate or deactivate tags filter. If this option is activated, you can access to the tags management screen",
      "CONTRIBUTIVE-MAP": "This option allows users to contribute to your map by publishing their points themselves",
      "UBER": "This option allows you to activate Uber service which will appear on a petal around points. Thanks to this service, users can search for an Uber driver",
      "ADWORDS": "This option allows you to use Google Adwords to display ads in points’ detailed views",
      "ARVIEW": "This option allows you to enable augmented reality view on mobile by tilting your phone vertically",
      "ARVIEW-RADIUS": "This option allow you to define the maximum radius to display pinpoints in augmented reality (meters)",
      "ARVIEW-CONFIGURATION": "This option allow you to define which configuration you want to use. For the indoor configuration, the objects in AR and the geofencing to reach a destination will be smaller",
      "USER-TOUR": "This option allow you to enable user tour on your livemap which details selected features step by step",
      "ADD-PINPOINTS": "To add points to your list, long click on the map or with add location box.",
      "EDIT-MODE": "You can now drag and drop pinpoints to place them on the map or drag the existing pinpoints to change their coordinates.",
      "EDIT-MODE-TOOLTIP": "The edit mode allows you to place pinpoints on the map and to modify their coordinates."
    },
    "livemap-form": {
      "NO-LISTS": "No Lists",
      "EXTERNAL-SERVICES": "External Services",
      "GEOFEATURE-EDIT": "Edit geofeatures",
      "CONFIGURATION": "Map configuration",
      "NAVIGATION": "Navigation",
      "EXTERNAL-ID": "External ID",
      "COPY-CONFIGURATION": "Copy map configuration",
      "TITLE": "Title",
      "LINKED-LISTS": "Linked Lists",
      "ADD-LIST": "Add a list",
      "ADD-VENUE": "Add a venue",
      "CREATE-CONTENT": "How would you like to create your map ?",
      "FROM-SCRATCH": "Create empty map",
      "FROM-SCRATCH-TEXT": "Configure your map from start",
      "FROM-MODEL": "Create from model",
      "FROM-MODEL-TEXT": "Browse your models to use same options for your new map",
      "BROWSE-MODEL": "Browse my models",
      "TOOLTIP-DISABLE": "Add a list to the map to enable this option",
      "TOOLTIP-AR-DISABLE": "You don't have access to this feature",
      "SAVE": "Save my map",
      "SAVE-AND-GET": "Save and get my map",
      "SAVE-AS-MODEL": "Save as model",
      "MENU": "Menu",
      "VISIBLE-FIELDS": "Visible fields",
      "SIDEBAR-CONTENT": "Sidebar content",
      "OPENED-SIDEBAR": "Panneau par défaut",
      "MAP-USAGE": "Utilisation de la carte",
      "GEOCODING": "Geocoding",
      "BOUNDS": "Bounds",
      "LINKS": "Links",
      "CUSTOM-LAYER": "Custom layer",
      "CUSTOM-LAYERS": "Custom layers",
      "TILE-STYLE": "Map style",
      "EMPTY-HELP": "Mouse over a parameter to have more informations",
      "DIMENSIONS": "Dimensions",
      "INSTEAD-PIXEL": "For 600px, write 600",
      "PREVIEW": "Preview",
      "UPDATE-PREVIEW": "Update preview",
      "MAP": "Map",
      "EDIT-MODE": "Edit mode",
      "READ-ZENDESK": "Read on Zendesk",
      "PP-DISP": "Points displayed",
      "WORLD": "World",
      "LANG": "Language",
      "EN": "English",
      "FR": "French",
      "DE": "German",
      "ES": "Spanish",
      "IT": "Italian",
      "ZH": "Chinese",
      "PT": "Portuguese",
      "JP": "Japanese",
      "AR": "Arabic",
      "ADD": "Add",
      "PP": "Point",
      "USER": "User",
      "WIDTH": "Width",
      "HEIGHT": "Height",
      "LANDING": "Opening area",
      "AUTO": "Auto",
      "FIXED-AREA": "Fixed area",
      "IFRAME": "Iframe",
      "USER-POS": "User position",
      "NEW-MAP": "New Map",
      "ADD-LAYER": "Add from a link",
      "ADD-ASSET": "Add from editor",
      "UNTITLED-LIVEMAP": "Unnamed livemap",
      "CONFIRM-SAVE-MODEL": "Are you sure you want to save the map",
      "WARNING-MODEL": "This operation can't be undone",
      "USE-MODERATIVE": "You can either moderate content submited by user or let them publish automatically. Would you like to let users publish automatically ?",
      "NO-PINPOINT-DRAFT": "No draft points are available on this map."
    },
    "livemap-tags": {
      "TITLE": "Tags editor",
      "NAME": "Name",
      "CATEGORY": "Category",
      "COLOR": "Color",
      "ICON": "Icon",
      "PREVIEW": "Preview",
      "VISIBILITY": "Visibility",
      "SEARCH-FORM": "Show in filters/search form",
      "FEATURED": "Show as featured",
      "POINT-CARD": "Show in points details view (places)",
      "EVENT-CARD": "Show in event detail view",
      "POINT-HEADER": "Show in point's header (highlighted)",
      "WELCOME-CARD": "Show in welcome card"
    },
    "livemap-clustering": {
      "PP-ICON": "Use profile picture as icon",
      "CUSTOM-ICON": "Use a custom icon",
      "CHOOSE": "Choose",
      "CLUSTER": "Use cluster circle"
    },
    "snippet-options": {
      "ADWORDS": "Google Adwords",
      "SET-FIXED-AREA": "Set fixed area",
      "DEFAULT": "Default value",
      "DISPLAY-DATE": "Show publication dates",
      "HIDE-TIME": "Hide time",
      "EVENT": "Events first",
      "UBER": "Uber service",
      "GEOJSON-LAYERS": "Manage layers",
      "STYLE-WIZARD": "Create new style",
      "CLUSTERS": "Clusters",
      "USE-CLUSTERS": "Use clusters",
      "USE-CLUSTERS-BACK": "Use back-end clusters",
      "CLUSTERS-RADIUS": "Clusters radius",
      "MARKERS-SIZE": "Markers size",
      "NO-LAYERS": "This map has no layers",
      "INTRO-CARD": "Intro Card",
      "USER-TOUR": "Tooltips",
      "DISPLAY-HELP": "Help",
      "ZOOM": "Zoom control",
      "FULL-SCREEN": "Full screen",
      "FEATURED-LIST": "Lists",
      "HOME": "Home",
      "LOCATION": "Locate me",
      "SEARCH": "Search",
      "SEARCH-GEOCODING": "Search by geocoding",
      "GEOCODING-REGION": "Geocoding country",
      "DEFINE-CUSTOM-REGION": "Define a custom area",
      "REMOVE-CUSTOM-REGION": "Remove the custom area",
      "DRAGGING": "Drag",
      "SCROLL": "Scroll",
      "ROTATION": "Rotation & tilt",
      "INITIAL-ROTATION": "Initial map rotation",
      "INITIAL-TILT": "Initial tilt",
      "LOCKED": "Lock",
      "AUTO-PLAY": "Auto play",
      "AUTO-PLAY-TIMOUT": "Auto play timeout (ms)",
      "LOCK-AUTO": "Lock Autoplay",
      "SHOW-AUTHOR": "Show author",
      "SHOW-ADDRESS": "Show address",
      "SHOW-LISTS": "Show related lists",
      "SHOW-SIDEBAR": "Show sidebar",
      "SHOW-PINPOINT-DESC": "Show point description on click",
      "SHOW-PINPOINT-MEDIA": "Show the media of the pinpoint in the description",
      "ENABLE-PETALS": "Enable petals buttons",
      "EXPAND-DESCRIPTION": "Expand description",
      "FEATURED": "Featured",
      "SOURCES": "Sources",
      "LINKS-ENABLED": "Links enabled",
      "SAME-WINDOW": "Same window",
      "TAGS": "Tags",
      "CONTRIBUTIVE-MAP": "Contributive map",
      "MIN-ZOOM": "Min zoom",
      "MAX-ZOOM": "Max zoom",
      "SHARE-SNIPPET": "Share map",
      "ADSENSE-ID": "Adsense ID",
      "ADSENSE-SLOT": "Adsense slot",
      "DOUBLE-CLICK-ID": "Double click ID",
      "DOUBLE-CLICK-SLOT": "Double click slot",
      "DOUBLE-CLICK-SIZE": "Double click size",
      "DOUBLE-CLICK-TARGET": "Double click target",
      "ADSENSE-WIDTH": "Adsense width",
      "ADSENSE-HEIGHT": "Adsense height",
      "FILTER-BY-DATES": "Calendar",
      "FILTER-BY-DATES-MIN": "Minimum date for the calendar filter",
      "FILTER-BY-DATES-MAX": "Maximum date for the calendar filter",
      "MIN-ZOOM-DETAILS": "By default: world (zoom 2)",
      "MAX-ZOOM-DETAILS": "By default: buildings (zoom 18)",
      "CLICK-FULL-SCREEN": "Fullscreen map on tap (mobile)",
      "DEFAULT-STYLE": "Default style",
      "ARVIEW": "Enable augmented reality (mobile only)",
      "ARVIEW-SEARCHRADIUS": "Maximum distance to display pinpoints in AR view",
      "ARVIEW-CONFIGURATION": "Configuration to use for AR",
      "ARVIEW-TITLE": "Augmented reality",
      "INDOOR": "Indoor",
      "OUTDOOR": "Outdoor",
      "EMBED": "Enable EMBED share on the livemap",
      "EMBED-SHARE": "EMBED share"
    },
    "livemaps": {
      "MAPS": "Maps",
      "MODELS": "Models",
      "SEARCH": "Search",
      "NAME": "Name",
      "SEARCH-MAPS": "Search in maps...",
      "CREATE-MODEL": "+ Create a model",
      "CREATE-LIVEMAP": "+ Create a livemap",
      "REFRESH-PREVIEW": "Refresh preview",
      "DOWNLOAD-PNG": "Download PNG",
      "HASHTAG": "Search by tag"
    },
    "modal": {
      "modal-preview-livemap": {
        "CURRENT-PREVIEW": "Current preview",
        "PREVIEW-LIVEMAP": "Livemap's preview"
      },
      "modal-delete-livemap": {
        "DELETE-MAP": "Delete map",
        "DELETE-MAPS": "Delete maps",
        "DELETE-MODEL": "Delete model",
        "REALLY-DELETE": "Do you really want to delete",
        "MAP": "the map",
        "MAPS": "maps",
        "MODEL": "the model",
        "DELETE-PP": "Delete points from lists linked to this map",
        "DELETE-LIST": "Delete lists linked to this map",
        "DELETE-PP-S": "Delete points from the lists linked to these maps",
        "DELETE-LIST-S": "Delete lists linked to these cards"
      },
      "modal-share-livemap": {
        "SHARE-MAP": "Share Map",
        "SHORT-LINK": "Link",
        "SNIPPET": "Wemap javascript library"
      },
      "modal-warning-clustering": {
        "WARNING-CLUSTERING": "With clustering of points that are closed to each other, your map can appear crowded. You can set a maximum number of visible points in the 'Map layout' menu"
      },
      "modal-select-model": {
        "SELECT-MODEL": "Select your model"
      }
    },
    "notification": {
      "LIVEMAP": "Livemap ",
      "UPDATED-5-MINUTES": " has been updated. It will take 5 minutes for changes to be visible.",
      "ENTER-NAME": "You must enter a name before saving the livemap.",
      "0-100": "Should be between 0 and 100 in pourcent",
      "ERROR-GETTING-LIVEMAP": "Something went wrong when getting livemaps.",
      "ERROR-GETTING": "Something went wrong when getting livemap.",
      "ERROR-SAVING": "Something went wrong when saving livemap :",
      "ERROR-ADDING": "Something went wrong when adding list to the livemap.",
      "ERROR-UPDATING": "Something went wrong when updating default snippet options of livemap '",
      "ERROR-TOKEN": "There was an issue when getting your token. Please refresh the page.",
      "ERROR-CREATING": "Something went wrong when creating livemap.",
      "ERROR-UP": "Something went wrong when updating livemap.",
      "ERROR-DELETING-S": "Something went wrong when deleting livemaps",
      "ERROR-DELETING": "Something went wrong when deleting livemap '",
      "LIST-ADDED": "List has been added to the livemap.",
      "FROM-MODEL": "has been created from model.",
      "CREATED": "' has been created",
      "LIVEMAP-PREVIEW": "Livemap '",
      "PREVIEW": "' preview will be generated in a few minutes.",
      "PREVIEW-GENERATED": "' preview has been generated",
      "ASSET-ADDED": "Asset has been added to the livemap.",
      "DEL": " has been deleted",
      "DELETED": " livemaps have been deleted."
    },
    "livemap-editor": {
      "btns": {
        "xlsx": "Import an xlsx file",
        "app": "Connect an app",
        "manual": "Create point manually"
      },
      "HOW-TO-ADD": "How do you want to add points ?"
    }
  },
  "analytics": {
    "dashboard": {
      "ALL-LIVEMAPS": "All livemaps",
      "TITLE": "Dashboard - Maps",
      "DESCRIPTION": "Maps's data agregation",
      "GENERATE-REPORT": "Generate a report",
      "LOAD": "Load",
      "YESTERDAY": "Yesterday",
      "LAST-WEEK": "Last week",
      "LAST-MONTH": "Last month",
      "LAST-YEAR": "Last year",
      "BODY-TITLE": "Maps agregated",
      "AVG-SESSION": "Average session duration",
      "NO-DATA": "No data found",
      "UNI-SESS": "Unique sessions",
      "UNI-VIEWS": "Unique views",
      "USER-ACT": "User actions",
      "NO-OF-USERS": "Number of users",
      "LOCATION": "Location",
      "COUNTRY": "Country",
      "COUNT": "Count",
      "DEVICE": "Device",
      "MARKETING_CHANNEL": "Marketing channel",
      "PINPOINT": "Point",
      "ACT-PP": "actions on Pinpoint",
      "LIKE": "Like",
      "SHARE": "Share",
      "DIRECTION": "Direction",
      "OPEN": "Open",
      "INTRO-HELP": "Consult all statistics about your connected map here (actions, sessions, mapviews, users ...)",
      "PAGEVIEW": "Pages views per minute",
      "PP-EVENTS": "Behavior on points",
      "EV-EVENTS": "Behavior on events",
      "SESSIONS": "Sessions",
      "AVERAGE": "Average session duration",
      "PAGE-VIEWS": "Pages views",
      "EVENTS-PER-SESSION": "Events per session",
      "TOTAL-EVENTS-PER-SESSION": "Total events per session",
      "FILTER-BY-DATE": "Filter by date",
      "REFERRER": "Referrer",
      "REQUEST-STATISTICS": "Request statistics access",
      "FILTER-BY": "Filter by",
      "DATE-RANGE": "Enter a date range",
      "DAILY-VISITS-TITLE": "How many users have accessed your map?",
      "REFERRERS-TYPE-TITLE": "How did you attract your users?",
      "DEVICES-TYPE-TITLE": "What are the main types of devices used by your users?",
      "CITY-TITLE": "Where were your users located?",
      "MAP": "Map",
      "CONTENT": "Content",
      "SEARCH": "Search",
      "SERVICE": "Service",
      "KIOSK": "Kiosk"
    },
    "livemap": {
      "MAP": "Map",
      "MAP-OVER": "Map overview",
      "LOAD": "Load",
      "NOT-AVA": "This map is not available for this user or doesn't exist.",
      "TIME-SPENT": "Time spent (average time spent)",
      "MOST-OPEN": "Most open point",
      "NAME": "Name",
      "COUNT": "Count",
      "MOST-ACTED": "Most acted point"
    },
    "content": {
      "DAILY-PINPOINT-TITLE": "Daily tracking of points: Open and Shared",
      "OPEN": "Open",
      "SHARE": "Shared",
      "TOTAL-OPEN": "Total open points",
      "TOTAL-SHARE": "Total shared points",
      "TOTAL-LIKE": "Total liked points",
      "TOTAL-NAVIGATION": "Total 'Go There' points",
      "TOP-OPEN": "What were the most popular points?",
      "TOP-EVENT": "What were the most popular events?"
    },
    "search": {
      "DAILY-SEARCH-TITLE": "Daily tracking of searches",
      "TOTAL-TAG": "Total searches by tags",
      "TOTAL-QUERY": "Total searches by keywords",
      "TOTAL-FILTER": "Total searches by filters",
      "TOP-TAG": "What were the most popular tags?",
      "TOP-QUERY": "What were the most popular keywords?"
    },
    "service": {
      "DAILY-ITINERARY-TITLE": "Daily tracking of itineraries: Launched, Completed, and Halted",
      "LAUNCHED": "Launched",
      "COMPLETED": "Completed",
      "HALTED": "Halted",
      "DAILY-AR-TITLE": "Daily tracking of Augmented Reality: Enabled and Disabled",
      "AR-ENABLED": "Enabled",
      "AR-DISABLED": "Disabled",
      "TOTAL-LAUNCHED": "Total launched itineraries",
      "TOTAL-COMPLETED": "Total completed itineraries",
      "TOTAL-HALTED": "Total halted itineraries",
      "TOTAL-AR-ENABLED": "Total enabled AR",
      "TOTAL-AR-DISABLED": "Total disabled AR"
    },
    "kiosk": {
      "TOGGLE": "Compare data",
      "PLACEHOLDER": "Select kiosks",
      "SEARCH": "Search",
      "SELECT-TOOLTIP": "To be able to select a kiosk, you must first select a Livemap.",
      "TOGGLE-TOOLTIP": "Toggle between combined and individual kiosk views.",
      "CHART-TITLE": "Daily monitoring of kiosk usage",
      "AVG-TITLE": "Average usage time per activation",
      "CHART-EXPLANATION": "No kiosk selected. Select one or more kiosks to view the associated data.",
      "TOTAL-USER": "Total users",
      "AVG-TIME": "Average time"
    }
  },
  "blocks": {
    "header": {
      "LOGOUT": "Logout",
      "SUPPORT": "Support",
      "GETTING-STARTED-GUIDE": "Getting started Guide",
      "PROFILE": "Profile",
      "TUTORIAL": "Tutorial",
      "USERS": "Users",
      "DOC-DEVELOPERS": "Developers documentations",
      "MY-ACCOUNT": "My account"
    },
    "nav": {
      "CREATE": "Create",
      "MANAGE": "Manage",
      "EXPLORE": "Explore",
      "MAP": "Map",
      "LIST": "List",
      "POINT": "Point",
      "TRIP": "Trip",
      "AREA": "Area",
      "PORTAL": "Portal",
      "EVENT": "Event",
      "ASSET": "Asset",
      "MY-MAPS": "My maps",
      "MY-LISTS": "My lists",
      "MY-POINTS": "My points",
      "MY-TRIPS": "My trips",
      "MY-AREAS": "My areas",
      "MY-APPS": "My apps",
      "MY-PORTALS": "My portals",
      "MY-EVENTS": "My events",
      "MY-ASSETS": "My assets",
      "MY-VENUES": "My venues",
      "STATISTICS": "Statistics",
      "SUPPORT": "Support",
      "VENUES": "Venue"
    }
  },
  "common": {
    "ADD": "Add",
    "ACTIVATED": "Activated",
    "BACK": "Back",
    "CANCEL": "Cancel",
    "CHANGE-DESCRIPTION": "Change description",
    "CHANGE": "Change",
    "CLEAR-SELECTION": "Clear selection",
    "CLOSE": "Close",
    "COLLECTION-AREAS": "areas",
    "COLLECTION-ASSETS": "assets",
    "COLLECTION-EVENTS": "events",
    "COLLECTION-LISTS": "lists",
    "COLLECTION-FEEDS": "feeds",
    "COLLECTION-LIVEMAPS": "livemaps",
    "COLLECTION-POINTS": "points",
    "COLLECTION-PORTALS": "portals",
    "COLLECTION-TRIPS": "trips",
    "COLLECTION-USERS": "users",
    "COLLECTION-VENUES": "venues",
    "CONFIRM-DELETE": "Are you sure you want to delete it?",
    "CREATE": "Create",
    "UPDATE": "Update",
    "DELETE-SELECTION": "Delete selection",
    "DELETE": "Delete",
    "DESCRIPTION": "Add description",
    "DUPLICATE": "Duplicate",
    "EDIT": "Edit",
    "ERROR-LIKE": "Something went wrong when liking ",
    "ERROR-PROPERTIES": "Server returned errors with those properties :",
    "ERROR": "An error has occurred",
    "HOURS": "Hours",
    "MINUTES": "Minutes",
    "NAME": "Name",
    "NEXT": "Next",
    "NO": "No",
    "OK": "Ok",
    "OPEN-IN-FULL-PAGE": "Open in full page",
    "OPEN-IN-NEW-PAGE": "Open in new page",
    "OPTIONAL": "Optional",
    "REMOVE-FROM-MAP": "Remove location",
    "REMOVE-FROM-LIST": "Remove from list",
    "REMOVE": "Remove",
    "RENAME": "Rename",
    "SAVE": "Save",
    "SEARCH": "Search",
    "SEARCHING": "Searching",
    "SELECTED": "selected",
    "SHARE": "Share",
    "SHOW": "Show",
    "UP-TO-DATE": "Up to date",
    "VISIT": "Go to map",
    "YES": "Yes",
    "OR": "Or",
    "NONE": "None",
    "NO-DATA-LIST": "Currently, you have no lists or your search yielded no results. Feel free to add your first list or adjust your search criteria to organize and find exactly what you need!",
    "NO-DATA-POINT": "Currently, you have no points or your search yielded no results. Feel free to add your first point or adjust your search criteria to organize and find exactly what you need!",
    "NO-DATA-MAP": "Currently, you have no maps or your search yielded no results. Feel free to add your first map or adjust your search criteria to organize and find exactly what you need!",
    "NO-DATA-TRIP": "Currently, you have no trips or your search yielded no results. Feel free to add your first trip or adjust your search criteria to organize and find exactly what you need!",
    "NO-DATA-AREA": "Currently, you have no areas or your search yielded no results. Feel free to add your first area or adjust your search criteria to organize and find exactly what you need!",
    "NO-DATA-PORTAL": "Currently, you have no portals or your search yielded no results. Feel free to add your first portal or adjust your search criteria to organize and find exactly what you need!",
    "NO-DATA-EVENT": "Currently, you have no events or your search yielded no results. Feel free to add your first event or adjust your search criteria to organize and find exactly what you need!",
    "NO-DATA-ASSET": "Currently, you have no assets or your search yielded no results. Feel free to add your first asset or adjust your search criteria to organize and find exactly what you need!"
  },
  "list": {
    "list-form": {
      "SETTINGS": "List settings",
      "LIST": "List",
      "CREATE": "Create",
      "NAME": "Name",
      "EDIT-TAGS": "Edit tags",
      "ADD-TAGS": "Add tags",
      "CREATE-LIVEMAP": "Create a livemap associated to this list",
      "CREATE-FEED": "Create a spreadsheet view associated to this list"
    },
    "list": {
      "SEARCH-OR-CREATE": "Search or create a list",
      "PRINT": "Print the list",
      "CREATE-FEED": "This list has been created via ",
      "CREATE-FEED-APP": " app.",
      "UNLINK-FEED": "Unlink",
      "SHOW-SOURCE": "Show source",
      "UPDATE-FEED": "Update",
      "EDIT-FEED": "Edit",
      "MAKE-ITINERARY": "Itinerary mode",
      "OPEN-SPREADSHEET": "Open in tableview",
      "UNDO-ITINERARY": "Simple list mode",
      "NO-NAME": "No name",
      "CHANGE-STATUS": "Change status",
      "SEE-MAPS": "See its maps",
      "NO-PREV": "No points preview.",
      "NO": "No",
      "S": "s",
      "NO-AUTHOR": "No author",
      "DRAFT": "Draft",
      "PUBLISHED": "Published"
    },
    "lists": {
      "LISTS": "Lists",
      "CHANGE-STATUS": "Change status",
      "SEARCH": "Search",
      "NAME": "Name",
      "AUTHOR": "Author",
      "SEARCH-LIST": "Search in list...",
      "INTRO-HELP": "In this tab you can manage lists you created or which were automatically created during import and app creation.",
      "DATE-CREATED": "Date created",
      "DATE-MODIFIED": "Date modified",
      "SHARE": "Share",
      "ADD-POINT": "Add point",
      "ADD-TO-MAP": "Add to map",
      "SEE-GEOFEATURES": "See  geofeatures"
    },
    "modal": {
      "modal-change-status": {
        "LISTS-STATUS": "Lists status",
        "STATUS": "Status",
        "PRIVATE": "Private",
        "SHARED": "Shared",
        "PUBLIC": "Public"
      },
      "modal-delete-list": {
        "FEED-LINKED-DELETED": "Please note that the feed linked to this list will also get deleted.",
        "REALLY-DELETE-LIST": "Do you really want to delete the list",
        "DELETE-LIST": "Delete list",
        "DELETE-PP": "Delete points from list"
      },
      "modal-delete-lists": {
        "REALLY-DELETE": "Do you really want to delete",
        "LIST": "list",
        "S": "s"
      },
      "modal-share-list": {
        "SHARE-LIST": "Share list"
      },
      "modal-show-source": {
        "SHOW-SOURCE": "Feed source"
      },
      "modal-share-livemap": {
        "MAPS": "Maps",
        "LIST-MAPS": "List of Maps using the List",
        "NO-MAPS": "No map is using the list"
      },
      "modal-create-options": {
        "CREATE-CONTENT": "From where do you want to create content?",
        "CREATED-PPS-BELONG": "Created points will belong to this list.",
        "CONNECT-AN-APP": "Connect an app",
        "CREATE-PPS-MANUALLY": "Create points manually",
        "IMPORT-XLSX": "Import an xlsx file"
      }
    },
    "notification": {
      "LIST": "List '",
      "UPDATED": "' has been updated",
      "UPDATED-S": "lists have been updated.",
      "CREATED": "' has been created",
      "ADDED": "List has been added to the livemap.",
      "DELETED": "' has been deleted",
      "DELETED-S": "lists have been deleted.",
      "ERROR-UPDATING": "Something went wrong when updating list '",
      "ERROR-UPDATING-S": "Something went wrong when updating lists -",
      "ERROR-GETTING": "Something went wrong when getting list.",
      "ERROR-GETTING-S": "Something went wrong when getting lists.",
      "ERROR-DELETING-S": "Something went wrong when deleting lists",
      "ERROR-DELETING": "Something went wrong when deleting list '",
      "ERROR-ADD-NAME": "Veuillez saisir un nom pour la liste"
    }
  },
  "venue": {
    "venue": {
      "SEARCH-OR-CREATE": "Search or create a venue",
      "SEARCH-LIST": "Search in Venues...",
      "PRINT": "Print ",
      "NEW-VENUE": " New venue",
      "EDIT-VENUE": "Update",
      "NO-NAME": "NO title",
      "CHANGE-STATUS": "Change status",
      "SEE-MAPS": "See its maps",
      "NO-PREV": "No points preview.",
      "NO": "Aucun(e)(s)",
      "S": "s",
      "NO-AUTHOR": "No author",
      "DRAFT": "Draft",
      "PUBLISHED": "Published",
      "VENUE-NAME": "Venue name",
      "ADD-BUILDING": "Add Building",
      "ADD-GRAPH": "Add Graph",
      "IMPORT-FROM-OSM": "Import from OSM",
      "EDIT-METADATA": "Edit metadata",
      "UPDATE-TILESET": "Update tilesets",
      "UPDATING-TILESET": "Updating {count} tileset(s)",
      "UPLOAD-FILE": "Upload file (OSM | GeoJSON)",
      "SEE-BUILDINGS": "See it's buildings",
      "ADDED-SUCCESSFULLY": "venue added successfully",
      "ADDED-FAILED": "Error when adding the venue.",
      "UPDATED-SUCCESSFULLY": "Venue updated successfully",
      "UPDATED-FAILED": "error when updating the venue.",
      "DATE-CREATED": "Creation date",
      "DATE-MODIFIED": "Modification date",
      "SAVE-CARD": "Save card",
      "TAGS": "Tags"
    },
    "venues": {
      "VENUES": " My Venues",
      "CHANGE-STATUS": "Change status",
      "SEARCH": "Search",
      "NAME": "Name",
      "AUTHOR": "Author",
      "SEARCH-LIST": "Search in Venues...",
      "INTRO-HELP": "Here, you can manage the venues that were either manually created or automatically generated during an import or app creation.",
      "DATE-CREATED": "Creation date",
      "DATE-MODIFIED": "Modification date",
      "SHARE": "Share",
      "ADD-BUILDING": "Add Building",
      "ADD-TO-MAP": "Add to map",
      "SEE-GEOFEATURES": "See geofeatures",
      "LINKED-VENUES": "Linked Venues",
      "NO-VENUES": "No venues",
      "ADD-VENUE": "Add Venue",
      "NOT-FOUND": "No venues found"
    },
    "geofeatures": {
      "DELETE-GEOFEATURES": "Delete the {count, plural, one {geofeature} other {geofeatures} }",
      "DELETE-GEOFEATURES-PP-WARNING": "A point is linked to a geofeature. This will also delete the location of this point."
    },
    "notification": {
      "ADDED": "Geofeature added to the venue successfully",
      "NOT-ADDED": "Geofeature not added to the venue",
      "MODIFIED": "{count, plural, one {Geofeature} other {{count} geofeatures} } updated successfully",
      "MERGED": "{count} geofeatures merged succesfully",
      "NOT-MODIFIED": "error when updating the geofeature",
      "DUPLICATED": "Geofeature duplicated successfully",
      "NOT-DUPLICATED": "error when duplicating the geofeature",
      "DELETED": "{count} object(s) deleted",
      "IMPORT-FROM-OSM-SUCCESS": "Import from OSM successful",
      "IMPORT-FROM-OSM-ERROR": "An error occured when importing from OSM",
      "VENUE-DELETED": "Venue deleted successfully",
      "VENUE-NOT-DELETED": "error when deleting the venue ",
      "VENUE-ADDED": "La venue a été ajouté avec succès",
      "VENUE-NOT-ADDED": "An error occured when adding the venue",
      "ERROR-GETTING-S": "An error occured when getting venues",
      "LEVEL-INVALID-FILE-TYPE": "Please upload a valid GeoJSON file",
      "LEVEL-IMPORT-FAILED": "Error when importing the level",
      "LEVEL-IMPORT-SUCCESS": "Level has been imported successfully",
      "GEOFEATURE-IMPORT-SUCCESS": "The objects have been imported successfully",
      "GEOFEATURE-IMPORT-LOADING": "Importing objects",
      "LEVEL-OUTSIDE-BUILDING": "The level is not inside the building",
      "NOT-DELETED": "error when deleting the geofeature",
      "LEVEL-ADDED": "Level added to the venue successfully",
      "LEVEL-NOT-ADDED": "Level not added to the venue",
      "LEVEL-MODIFIED": "Level updated successfully",
      "LEVEL-NOT-MODIFIED": "error when updating the Level",
      "LEVEL-DELETED": "Level deleted successfully",
      "LEVEL-NOT-DELETED": "error when deleting the Level",
      "BUILDING-ADDED": "Building added to the venue successfully",
      "BUILDING-NOT-ADDED": "Building not added to the venue",
      "BUILDING-MODIFIED": "Building updated successfully",
      "BUILDING-NOT-MODIFIED": "error when updating the Building",
      "BUILDING-DELETED": "Building deleted successfully",
      "BUILDING-NOT-DELETED": "Error when deleting the Level",
      "EMPTY-BUILDING-NAME": "Building name cannot be null",
      "LEVEL-NAME": "Level name cannot be null"
    },

    "level": {
      "EDIT-LEVEL": "Modify building level",
      "ADD-LEVEL": "Add building level",
      "CREATE-LEVEL-HELP": "To create a level, add a name, a value and draw the level area",
      "ADD-LEVEL-DISABLED": "You must add a building to create a level",
      "ADD-LEVEL-MANUALLY": "Add level manually",
      "ADD-LEVEL-IMPORT": "Import level from file",
      "ADD-LEVEL-CONFIRM-TITLE": "Import level",
      "ADD-LEVEL-COUNT": "You are going to import {count} geofeatures{countLevel, plural, =0 {} one { and a level} other {}}. Continue?",
      "DELETE-LEVEL": "Delete level",
      "DELETE-LEVEL-CONFIRM": "Are you sure you want to delete the level {name} ?",
      "BUILDING-LEVEL": "Levels",
      "LEVEL-NAME": "Level name",
      "VISIBLE": "Visible",
      "DEFAULT-LEVEL": "Default level",
      "LEVEL-VALUE": "Level value"
    },
    "building": {
      "CREATE-BUILDING-HELP": "To create a building, add a name and draw the building area",
      "BUILDINGS": "Buildings",
      "BUILDING-NAME": "Building name",
      "EDIT-BUILDING": "Modify building",
      "ADD-BUILDING": "Add building",
      "DELETE-BUILDING": "Delete building",
      "DELETE-BUILDING-CONFIRM": "Are you sure you want to delete the building {name} ?"
    },
    "geofeature": {
      "IMPORT-GEOFEATURE": "Import objects",
      "HELP-SNAP": "Enable/disable the snapping mode et the display of guiding lines. Hold the Alt key to enable/disable this feature."
    },
    "helper": {
      "IMPORT-FILE-HELPER": "A 'level' feature is required in the GeoJSON file. \n (indoor = \"level\" and level = level_number)"
    }
  },
  "import-sheet": {
    "import-sheet": {
      "CHOOSE-SHEET": "Select a sheet to import",
      "IMPORTING": "Import in progress. You will be notified when it finish, you can navigate freely on the platform.",
      "FINISH": "XLSX import finished.",
      "THEME": "Theme",
      "CUSTOMISATION": "Description customization",
      "CUSTOMISATION-STYLE": "Geoentities customization",
      "VALUE": "Value",
      "PREVIEW": "Preview result",
      "FIELD-SIMPLE": "Simple text",
      "FIELD-ICON": "Title with icon",
      "HIDE-TITLE": "Hide title",
      "SHOW-TITLE": "Show title",
      "FIELD-BUTTON": "Action button",
      "UPLOAD-DOCUMENT": "Upload your document",
      "HELP-PERMISSION": "Make sure your document is visible for users who have the link",
      "HELP-TITLE-ONE": "Document header",
      "HELP-TITLE-TWO": "Document data",
      "HELP-TITLE-THREE": "Document permission",
      "HELP-ONE": "Must start at first row and column",
      "HELP-TWO": "Must not have blank space at the beginning and at the end of cell",
      "HELP-THREE": "Must have start date and end date",
      "HELP-FOUR": "Must be in format 'yyyy-mm-dd' or ISO like '2016-02-29T18:15:28.762Z'",
      "HELP-FIVE": "Must be visible by all users owning the link",
      "HERE": "here",
      "GETTING-STARTED": "Find our getting started guide",
      "IMPORT": "Import",
      "FORMAT-FILE-UPLOAD": "Format of file uploaded is wrong. Please upload an XLSX file.",
      "LINK": "Link",
      "LINK-INFORMATION": "This field must be a full URL. Exemple: https://getwemap.com",
      "EXTERNAL-DATA": "External data",
      "PHONE": "Phone",
      "COUNTRY": "Country",
      "COUNTRY-INFORMATION": "The country field must correspond to the code ISO 3166-1 alpha-2 of the country to be valid, in which case if it does not correspond this field will be refused.",
      "OPENING-HOURS": "Opening hours",
      "MULTIPLE-PROPERTY": "You can add one or multiple values to this field",
      "ADVANCED-FIELDS": "Advanced fields",
      "NAME": "Name",
      "ID": "Unique ID",
      "DESCRIPTION": "Description",
      "ALTITUDE": "Altitude",
      "LATITUDE": "Latitude",
      "LONGITUDE": "Longitude",
      "GEO_ENTITY_SHAPE": "Geo entity ",
      "ADDRESS": "Address",
      "PP-FEED-TWITTER": "Twitter feed",
      "PP-FEED-FACEBOOK": "Facebook feed",
      "PP-FEED-ICAL": "iCal feed",
      "MEDIA-CREDITS": "Media credits",
      "PICTURE-OR-VIDEO": "Picture or video",
      "CAROUSEL": "Image carousel",
      "CATEGORY": "Category",
      "START-DATE": "Start Date",
      "END-DATE": "End Date",
      "TAGS": "Tags",
      "CHECK-SHEET-RECORD": "Check sheet record number: ",
      "IMPORT-AGAIN": "Import this file again after correcting errors.",
      "THE": "The",
      "DESC": "Descending",
      "IMPORT-LIST-NAME": "Name",
      "IMPORT-IN-LIST": "Import in list",
      "IMPORT-LIST": "Go to the list containing imported data:",
      "PP-LOADED-DRAFT": "The point will be loaded as draft in the list only if it does not have address.",
      "ROWS-PUBLISHED": "rows have been published.",
      "OF": "of",
      "INTRO-HELP": "Here you can import your content from a .xlsx file and define all point settings (name, address, description...)",
      "PROPERTY": "Property",
      "EDIT-MAPPING": "Edit fields mapping",
      "VALIDATE-MAPPING": "Validate mapping",
      "CATEGORY-ICON": "Category icon",
      "PROPERTIES": "Properties",
      "VALUES": "Values",
      "OPTIONAL": ""
    },
    "modal": {
      "modal-category-icon": {
        "CATEGORY-TITLE": "Manage categories icons",
        "ICON": "Icon",
        "NAME": "Category name"
      }
    },
    "notification": {
      "FILL-MANDATORY": "Please fill the mandatory fields.",
      "ERROR-IMPORTING": "Something went wrong when importing pinpoints.",
      "ERROR-IMPORTING-PINPOINTS": "Error while importing pinpoints ("
    }
  },
  "user": {
    "modal-request-access": {
      "NOT-PERMISSION": "You do not have access to the",
      "FEATURE": "feature",
      "QUESTION": "Do you want to request access?"
    },
    "profile": {
      "PROFILE-PHOTO": "Profile picture",
      "BACKGROUND-PHOTO": "Background picture",
      "UPLOAD-PHOTO": "Upload your photo",
      "USERNAME": "Username",
      "UPDATE": "Update",
      "CHANGE-PASS": "Change password",
      "YOUR-PASS": "Your password",
      "NEW-PASS": "New password",
      "CONFIRM-PASS": "Confirm password",
      "CHANGE": "Change",
      "OPT-SETTINGS": "Optional settings",
      "NOTIFICATION": "Enable help notifications",
      "PASSWORD-RECOMANDATION": " Password recommandations:",
      "TEN-CHAR": " at least 10 characters",
      "LOWER-CHAR": "At least one lowercase character ",
      "SPECIAL-CHAR": "             Inclusion of at least one special character, e.g., ! &#64; # ? "
    },
    "modal-upload-crop-image": {
      "UPLOAD-PROFILE-PHOTO": "Upload profile picture",
      "UPLOAD-BACKGROUND-PHOTO": "Upload backgroud picture"
    },
    "users": {
      "USERS": "Users",
      "ACCESS_ACCOUNT": "Access account",
      "FOLLOWERS": "Followers",
      "SUPERVISED_ACCOUNT": "Supervised accounts",
      "ALIAS": "Authorized Access",
      "FOLLOWING": "Following",
      "UNFOLLOW": "Unfollow",
      "FOLLOW": "Follow",
      "FOLLOW-USERS": "Follow users",
      "SEARCH-USERS": "Search by name ...",
      "SEARCH-BY-NAME": "Filter results by first or last name ...",
      "SEARCH-BY-ACCOUNT": "Filter results by name account ...",
      "NO-RECORDS-FOUND": "No records !",
      "NO-LINKED-ACCOUNT-FOUND": "No linked accounts found !",
      "SEARCH": "Search",
      "PLEASE-SEARCH-NAME": "Please search for a name !",
      "PASSWORD-NOT-MATCH": "The password doesn't match your new password",
      "WRONG-CURRENT-PASSWORD": "The current password is incorrect",
      "TOO-SHORT-PASSWORD": "The new password must contain at least 9 characters",
      "TOO-COMMON-PASSWORD": "The new password is too common",
      "ENTIRELY-NUMERIC-PASSWORD": "The password must not be entirely numeric",
      "ALIASES": "Show aliases accounts"
    },
    "signin": {
      "TITLE": "Sign in to get in touch",
      "FORGOT_PASSWORD": "Forgot your password ?",
      "LOGIN": "Log in",
      "LOGIN_WITH_SSO": "Log in with SSO",
      "NOT_REGISTERED": "Not registered?",
      "CREATE": "Create an account"
    },
    "notification": {
      "PASSWORD-UPDATED": "Your password has been updated.",
      "PROFILE-UPDATED": "Your profile has been updated.",
      "BACKGROUND-UPDATED": "Your background picture has been updated.",
      "PICTURE-UPDATED": "Your profile picture has been updated.",
      "FOLLOW": "User was followed successfully",
      "UNFOLLOW": "User was unfollowed successfully",
      "ACCESS": "Access to feature '",
      "ACCESS-DENIED": "You do not have access to this feature.",
      "REQUESTED": "' requested"
    },
    "error-interceptor": {
      "ERROR-UPDATE": "Something went wrong when updating user.",
      "ERROR-FOLLOWING": "Something went wrong when following user.",
      "ERROR-UNFOLLOW": "Something went wrong when unfollowing user.",
      "ERROR-GETTING-FOLLOWER": "Something went wrong when getting followers.",
      "ERROR-GETTING-TOKEN": "Something went wrong when getting token.",
      "ERROR-GETTING-USER": "Something went wrong when getting user.",
      "ERROR-GETTING-USERS": "Something went wrong when getting users.",
      "ERROR-GETTING-ALIAS": "Something went wrong when getting alias."
    }
  },
  "help": {
    "tutorial": {
      "modal-welcome-step1": {
        "WELCOME": "Welcome to Wemap Pro platform!",
        "EASY-POWERFUL": "Wemap Pro is an easy and powerful tool to ",
        "BUILD-CONNECTED": "build your connected maps with your content.",
        "FOLLOW": "Follow this tutorial to learn how to use it",
        "MINUTE": " in one minute!"
      },
      "modal-welcome-step2": {
        "CREATE-PLUG": "Create or plug in content",
        "WAYS": "There are 3 ways to create or plug in content into your map:",
        "DOCUMENTS": "Documents",
        "XLSX": "(xlsx)",
        "APPS": "Apps",
        "APPS-EX": "(twitter, FB...)",
        "CREATE-POINTS": "Create points",
        "BY-HAND": "by hand"
      },
      "modal-welcome-step3": {
        "EDIT-CUSTOMIZE": "Edit and customize points",
        "CUSTOMIZE-PPS": "You can edit and customize your points by going in Lists tab.",
        "CHANGE": "Change their category icon, title, type, description, tags..."
      },
      "modal-welcome-step4": {
        "SET-MAP": "Set your map",
        "CONTENT-GENERATED": "Once your content is generated, you have to",
        "BASIC-INFO": "fill basic information and add sources",
        "YOUR-MAP": "(list or points) to your map"
      },
      "modal-welcome-step5": {
        "EMBED-MAP": "Embed your map on your website!",
        "YOUR": "Your",
        "FULLY-RESPO": " fully responsive",
        "MAP-READY": " map is ready!",
        "EMBED": "To embed it on your website, just copy paste",
        "SNIPPET": "the snippet.",
        "NO-CODE": " No code is needed!",
        "CREATE-CONTENT": "Create content"
      },
      "common": {
        "PREV": "Previous",
        "NEXT": "Next",
        "SKIP": "Skip"
      }
    }
  },
  "selection": {
    "THE": "The",
    "RESULTS-THIS-PAGE": "results of this page are selected.",
    "SELECT-THE": "Select the",
    "SELECT-MAIN-SEARCH": "search results",
    "THE-FIRST": "The firsts",
    "RESULTS-MAIN-SEARCH": "search results are selected",
    "PLEASE-WAIT": "Please wait while we select all results"
  },
  "breadcrumb": {
    "MAPS": "Maps",
    "NEW-MAP": "New map",
    "LISTS": "Lists",
    "NEW-LIST": "New list",
    "PPS": "Points",
    "NEW-PP": "New point",
    "PORTALS": "Portals",
    "NEW-PORTAL": "New portal",
    "EVENTS": "Events",
    "NEW-EVENT": "New event",
    "FEEDS": "Feeds",
    "APPS": "Apps",
    "TRIPS": "Trips",
    "NEW-TRIP": "New trip",
    "AREAS": "Areas",
    "NEW-AREA": "New area",
    "NEW-APP": "New app",
    "CONNECT-APP": "Connect an app",
    "NEW-INSTA-APP": "Import instagram",
    "APP-TYPES": "Types",
    "NEW-APP-TYPES": "New type",
    "EDIT-APP-TYPES": "Edit type",
    "IMPORT-SHEET": "Import xlsx",
    "ANALYTICS": "Analytics",
    "MAPS-ANALYTICS": "Maps",
    "PROFILE": "Profile",
    "USERS": "Users",
    "CONTACT": "Contact us",
    "GOOGLE-SHEETS": "Import Google Sheets",
    "JSON": "Import JSON file",
    "DEVELOPER-SETTINGS": "Developer settings",
    "STATISTICS": "Statistics",
    "PRINT": "Print",
    "MANAGE-LAYERS": "Manage layers",
    "MANAGE-STYLES": "Manage styles",
    "ASSETS": "Assets",
    "NEW-ASSET": "New asset",
    "MAP-PARAMS": "Map parameters",
    "VENUE": "Venue",
    "NEW-VENUE": "New venue",
    "EDIT-GEOFEATURE": "Geofeatures edit"
  },
  "title": {
    "MAPS": "Maps",
    "NEW-MAP": "New map",
    "LISTS": "Lists",
    "NEW-LIST": "New list",
    "PPS": "Points",
    "NEW-PP": "New point",
    "PORTALS": "Portals",
    "NEW-PORTAL": "New portal",
    "EVENTS": "Events",
    "NEW-EVENT": "New event",
    "FEEDS": "Feeds",
    "APPS": "Apps",
    "TRIPS": "Trips",
    "NEW-TRIP": "New trip",
    "AREAS": "Areas",
    "NEW-AREA": "New area",
    "NEW-APP": "New app",
    "EXPLORE": "Explore",
    "CONNECT-APP": "Connect an app",
    "EDIT-APP-TYPES": "Edit type",
    "IMPORT-SHEET": "Import xlsx",
    "ANALYTICS": "Analytics",
    "PROFILE": "Profile",
    "USERS": "Users",
    "DEVELOPER-SETTINGS": "Developer settings",
    "PRINT": "Print",
    "MANAGE-LAYERS": "Manage layers",
    "MANAGE-STYLES": "Manage styles",
    "ASSETS": "Assets",
    "NEW-ASSET": "New asset",
    "MAP-PARAMS": "Map parameters"
  },
  "introcard": {
    "CUSTOMIZE": "Customize",
    "WHEN": "When ?",
    "WHEN-TEXT": "Prepare your visit",
    "WHEN-BTN": "\"When\" module: filter by date",
    "AROUND-ME": "Around me",
    "AROUND-ME-TEXT": "Discover events near you right now",
    "AROUND-ME-BTN": "\"Around me\" module: geolocate",
    "WHERE": "Where ?",
    "WHERE-TEXT": "Type your search",
    "WHERE-BTN": "\"Where\" module: filter by address",
    "TAGS": "Set your preferences",
    "TAGS-TEXT": "Choose one or several themes",
    "TAGS-BTN": "\"Tags\" module: filter by keywords",
    "UPLOAD": "Upload a picture"
  },
  "usertour": {
    "CUSTOMIZE": "Customize",
    "TOOLTIP": "Tooltips",
    "FILTER": "Filter",
    "MENU": "Menu",
    "FILTER-BODY": "Click on the \"Filter\" button to browse the results by keyword and date.",
    "MENU-BODY": "Find your selections, events around you or activate help.",
    "LIVEBAR-BODY-PINPOINT": "Click on \"Show list\" to see all points.",
    "SHAREVIEW-BUTTON": "Shareview button",
    "PINPOINT-PETALS": "Point petals"
  },
  "layers": {
    "form": {
      "ALTITUDE": "Altitude",
      "ALTITUDE-INFO": "The altitude (in meters) of this layer, for multi-floor maps.",
      "CLUSTERING": "Clustering",
      "CLUSTERING-INFO": "Use this layer for front-end hierarchy clustering.",
      "MAX-ZOOM": "Maximum zoom",
      "MAX-ZOOM-INFO": "The maximum zoom at which the layer is visible (exclusive).",
      "MIN-ZOOM": "Minimum zoom",
      "MIN-ZOOM-INFO": "The minimum zoom at which the layer is visible (inclusive).",
      "CLICK-ACTION": "Action on click",
      "HOVER-ACTION": "Action on hover",
      "TYPE": "Type",
      "TYPE-INFO": "The type of layer format (GeoJSON, vector tiles or image).",
      "URL": "URL",
      "URL-INFO": "The URL from which to fetch the layer's data."
    }
  },
  "tags": {
    "SEARCH": "Search tags by slug",
    "CLICKABLE": "Make tags clickable",
    "COLOR": "Change tags color",
    "NOT-CLICKABLE": "Make tags not clickable",
    "VISIBILITY": "Change tags visibility",
    "ICON": "Change tags icon",
    "ACTIVATE": "Activate tags on this map",
    "TAG-FILTERING-LOGIC": "Filtering logic for tags",
    "TAG-FILTERING-AND": "AND",
    "TAG-FILTERING-OR": "OR",
    "TAG-FILTERING-DEFAULT": "Default",
    "INTERACTIVE-EXAMPLE": "Show interactive example",
    "OR-LOGIC-LABEL": "With an \"OR\" logic, all points with at least one of the selected tags will appear in the results",
    "AND-LOGIC-LABEL": "With an \"AND\" logic, points must have all the selected tags to appear in the results",
    "DEFAULT-LOGIC-LABEL": "With a default logic, tags within a category are cumulative and tags between categories are exclusive",
    "LOGIC-LABEL-START": "Points with tags",
    "LOGIC-LABEL-END": "will appear in the results",
    "NO-TAG": "This map has no tags. You can add them to your points and re-synchronize tags list.",
    "CLEAN": "Clean obsolete tags",
    "CLEAN-HELP": "Are you sure you want to delete all obsoletes tags? This cannot be undone.",
    "ORDER": "Order tags alphabetically",
    "CATEGORY": "Change tags category",
    "CREATE-CATEGORY": "Create new tag category"
  },
  "print": {
    "IMG-RES": "Image resolution",
    "USE-AS-PREVIEW": "Use as preview"
  },
  "asset": {
    "NEW-ASSET": "Asset settings",
    "INPUT": "Asset name",
    "LAYER": "Data layer",
    "STYLE": "Style sheet",
    "LINK-TO-MAP": "Link this asset to livemap",
    "LINK-STYLE-TO-MAP": "Link this style to livemap",
    "LINK-LAYER-TO-MAP": "Link this layer to livemap",
    "geojson": {
      "NEW-LAYER": "New GeoJson layer",
      "NEW-PROP": "New property",
      "NEW-VALUE": "New value",
      "PROPS": "Properties",
      "IMPORT": "Import from file",
      "PROPERTY-EXISTS": "This property already exists"
    },
    "style": {
      "COLORS": "Colors",
      "key": {
        "BACKGROUND": "Lands",
        "WATER": "Waters",
        "ROADS": "Roads",
        "LANDUSE_PARK": "Parks"
      }
    },
    "list": {
      "NAME": "Name",
      "HELP-INTRO": "Create assets like additionnal styles or geographic data layers to enrich your livemaps !"
    },
    "modal": {
      "SELECT-ALL": "Select all the page",
      "DELETE": "Delete an asset",
      "DELETE-SURE": "Are you sure you want to delete asset",
      "DELETE-MULTIPLE-SURE": "Are you sure you want to delete these assets",
      "RENAME": "Rename an asset",
      "RENAME-SURE": "Type the new name of asset",
      "UPDATE": "Save an asset",
      "UPDATE-SURE": "Be careful, this asset will be updated on all your livemap using it"
    },
    "create": {
      "BUTTON": "Create asset"
    },
    "notification": {
      "ASSET": "Asset '",
      "CREATED": "' has been created",
      "DELETED": "' has been deleted",
      "DELETED-S": "assets have been deleted",
      "ERROR-GETTING-ASSETS": "Something went wrong when getting assets.",
      "ERROR-DUPLICATING": "Something went wrong when duplicating asset '",
      "DUPLICATED": "' has been duplicated!",
      "ERROR": "An error occured."
    },
    "error-interceptor": {
      "ERROR-GETTING": "Something went wrong when getting asset.",
      "ERROR-CREATING": "Something went wrong when creating asset.",
      "ERROR-UPDATING": "Something went wrong when updating asset.",
      "ERROR-DELETING": "Something went wrong when deleting asset."
    }
  },
  "event": {
    "event": {
      "INTRO-HELP": "Manage event's dates here",
      "DELETE-DATE": "Are you sure you want to delete those dates ?",
      "DELETE": "Are you sure you want to delete the event "
    },
    "events": {
      "INTRO-HELP": "In this tab you can manage events you created manually or which were automatically added (from import, apps...)",
      "SEARCH-EVENTS": "Search in events...",
      "FROM-DATE": "From",
      "TO-DATE": "to",
      "SEARCH-LIST": "Search by list",
      "SEARCH-PINPOINT": "Search by point",
      "SELECT-LIST": "Select list",
      "SELECT-PINPOINT": "Select point"
    },
    "event-form": {
      "CREATE-EVENT": "Event settings",
      "LINKED-PINPOINT": "Linked point",
      "DATES": "Dates",
      "START-DATE": "Start date",
      "END-DATE": "End date",
      "SELECT-DATE-RANGE": "Enter a date range",
      "INVALID-DATE-RANGE": "The selected period is not valid, change the date or time.",
      "START-TIME": "Start time",
      "END-TIME": "End time",
      "ERROR-REQUIRED-START-DATE": "The start date is required",
      "ERROR-REQUIRED-END-DATE": "The end date is required",
      "ERROR-REQUIRED-START-TIME": "The start time is required",
      "ERROR-REQUIRED-END-TIME": "The end time is required",
      "ERROR-INVALID-START-DATE": "The start date is not valid",
      "ERROR-INVALID-END-DATE": "The end date is not valid",
      "NAME": "Name"
    },
    "event-media": {
      "CHANGE": "Change image",
      "REMOVE": "Remove image"
    },
    "modal": {
      "EVENT-PIC": "Event picture",
      "REMOVE-PIC": "Remove picture ?",
      "delete": {
        "QUESTION": "Are you sure you want to delete",
        "DELETE-EVENT": "Delete event",
        "EVENT": "the event",
        "EVENTS": "events"
      }
    },
    "notification": {
      "EVENT": "Event '",
      "DELETED": "' deleted succesfully.",
      "SAVED": "' has been saved.",
      "CREATED": "' has been created",
      "PRIVATE-OR-NOT-EXIST": "This event is private or does not exist.",
      "ERROR-GETTING": "Something went wrong when getting event.",
      "ERROR-GETTING-S": "Something went wrong when getting events.",
      "CROSSING": "Please make sure dates ranges are not crossing",
      "ERROR-DELETING": "Something went wrong when deleting events - ",
      "INFO-REQUIRED": "Pinpoint, name and dates are required.",
      "VERIFY-DATE": "There is a problem with your dates, please verify if they are well filled",
      "ERROR-UPDATING": "Something went wrong when updating event.",
      "ERROR-CREATING": "Something went wrong when creating event."
    }
  },
  "developers": {
    "ADD-APP": "Add an application",
    "CREATE-NAME": "Save a new application",
    "CREATE-APP": "Save",
    "REGISTERED-APPS": "Your registered applications",
    "DOC-TITLE": "Documentation",
    "TOKEN": "User token",
    "TOKEN-DESC": "This token is the one that you should use to instanciate your livemap with our SDK.",
    "DOC-TEXT": "Creating an application connected to Wemap allows you to deploy livemap faster than ever. We provide development toolkits for developers : Android, iOS and Javascript SDK, and REST API.",
    "DOC-GOTO": "Go to documentation"
  },
  "auth": {
    "SENT-EMAIL": "We sent you an email to choose a new password, please check your inbox.",
    "ERROR-SENT-EMAIL": "Something went wrong when sendind you an email to choose a new password. Please try again.",
    "ERROR-USERNAME-PASSWORD": "Wrong username or password",
    "ERROR-SSO": "Something went wrong when logging in with SSO.",
    "USER-FAILED": "User initialization failed"
  },
  "page": {
    "GO-BACK": "Go back to the sign in page",
    "FOOTER": "Privacy and Terms",
    "OF": "of"
  },
  "comment": {
    "ERROR-GETTING": "Something went wrong when getting comments.",
    "ERROR-POSTING": "Something went wrong while posting comment.",
    "FILL": "Please enter a valid comment.",
    "SUCCESS": "Comment was posted successfully.",
    "DELETE": "Comment was deleted successfully."
  },
  "category": {
    "CATEGORY": "Category '",
    "VALID": "You should select a valid file to upload.",
    "DELETED": "' deleted succesfully."
  },
  "upload": {
    "CLICK": "Click to upload",
    "DROP": "Drop your file",
    "UPLOAD-FILE": "Upload file"
  },
  "parameters": {
    "WARNING-OVERRIDE": "Be careful, this action is irreversible and will override the snippet of the selected maps.",
    "EXPORT-CONFIGURATION": "Export this configuration to other maps"
  },
  "indoor-feature": {
    "notification": {
      "ERROR-GETTING": "An error occurred while retrieving the indoor features.",
      "ERROR-UPDATING": "An error occurred while updating the geofeature.",
      "ERROR-ALREADY-LINK": "The location is already linked to a point.",
      "ERROR-BUILDING": "An error occurred while retrieving the building.",
      "WARNING-BUILDING": "No buildings were found at this position"
    },
    "error": {
      "NOT-FOUND-CTA": "Go back home",
      "NOT-FOUND-TITLE": "This page does not exist",
      "NOT-FOUND-TEXT": "The page you are looking for does not exist. Please try again or contact support.",
      "UNAUTHORIZED-CTA": "Go back home",
      "UNAUTHORIZED-TEXT": "You are not authorized to access this page. Please try again or contact support.",
      "UNAUTHORIZED-TITLE": "Unauthorized"
    }
  },
  "toolbar": {
    "SELECT": "Select",
    "SELECT-MULTIPLE": "Select multiple",
    "DRAW-POLYGON": "Draw polygon",
    "DRAW-LINE": "Draw line",
    "DELETE": "Delete",
    "UPLOAD-PLAN": "Import a plan (.png, .jpg)",
    "DUPLICATE": "Duplicate",
    "FILTER": "Filter",
    "STYLE-FROM-LIVEMAP": "From a livemap",
    "STYLE-FROM-LINK": "From an URL",
    "UPLOAD": "Import objects",

    "dialog": {
      "STYLE-URL-TITLE": "Add a style from an URL"
    }
  }
}
